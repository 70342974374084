// Version 0.1.0, 11.9.24. Lisätty 4 uutta laitetta Metsäfibrelle.
//import logo from './3-light-tight.svg';
import logo from './1-dark-tight.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



import Button from 'react-bootstrap/Button';
import ReactSwitch from 'react-switch';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import Tooltip from 'react-bootstrap/Tooltip';
import floppy from './floppy.svg';
import clock from './clock.svg';
import gear from './gear.svg';
import database from './database.svg';
import brilliance from './brilliance.svg';
import map from './map.svg';
import logout from './box-arrow-right.svg';

//import * as Icons from 'react-bootstrap-icons';

// Recharts komponentti
//import Chart from './Chart';
import moment from 'moment';

import loginService from './services/login';

// Google maps
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow
} from "@vis.gl/react-google-maps";


const grafana_url = 'https://opus.iottech.fi/grafana';
//const grafana_url = 'http://51.15.114.61:3000';
const iottech_url = 'https://opus.iottech.fi'; // TÄMÄ ON OIKEA!
//const iottech_url = 'http://51.15.114.61:7095';
//const iottech_url = 'http://172.16.0.103:7095'; // Kotitesti
//const iottech_url = 'http://127.0.0.1:7095';


// https://www.brainstormcreative.co.uk/react-js/react-bootstrap-icons/

// https://react-bootstrap.netlify.app/docs/components/modal/

// Opettele tästä https://contactmentor.com/create-modal-react-js-overlay/

// Otsikot laitteille
//const deviceTitles = new Map();
const deviceTitles = new Object();
/*
deviceTitles.set('7786373', '7786373 - Kaarina itä TEE');
deviceTitles.set('7786374', '7786374 - Kaarina länsi');
deviceTitles.set('7786375', '7786375 - Parainen itä');
deviceTitles.set('7786376', '7786376 - Parainen länsi');
*/

deviceTitles['7785075'] = '7785075';
deviceTitles['7786393'] = '7786393';
deviceTitles['7786394'] = '7786394';
deviceTitles['7786395'] = '7786395';
deviceTitles['7786841'] = '7786841';
// Nämä tuli takaisin MetsäFibreltä koska niihin ei saatu yhteyttä. Testissä Mikkelissä.
deviceTitles['7786377'] = '7786377*'; //POIS -> EI TOIMINUT. Korvattu toisella
deviceTitles['7786379'] = '7786379*'; //POIS -> EI TOIMINUT. Korvatttu 

// Väylä
deviceTitles['7786373'] = '7786373 - Kaarina itä';
deviceTitles['7786374'] = '7786374 - Kaarina länsi';
deviceTitles['7786375'] = '7786375 - Parainen itä';
deviceTitles['7786376'] = '7786376 - Parainen länsi';

// Metsäfibre - Joutseno
deviceTitles['7786370'] = '1 - 7786370';
deviceTitles['7786380'] = '2 - 7786380';
deviceTitles['7786378'] = '3 - 7786378';
deviceTitles['7786398'] = '4 - 7786398';
deviceTitles['7786369'] = '5 - 7786369';
deviceTitles['7786397'] = '6 - 7786397';
deviceTitles['7786395'] = '7 - 7786395';
deviceTitles['7786393'] = '8 - 7786393';

// Jäähalli Rauma
deviceTitles['7786381'] = '1 - 7786381';
deviceTitles['7786382'] = '2 - 7786382';
deviceTitles['7786383'] = '3 - 7786383';
deviceTitles['7786384'] = '4 - 7786384';
deviceTitles['7786385'] = '5 - 7786385';
deviceTitles['7786386'] = '6 - 7786386';
deviceTitles['7786387'] = '7 - 7786387';
deviceTitles['7786388'] = '8 - 7786388';
deviceTitles['7786389'] = '9 - 7786389';
deviceTitles['7786390'] = '10 - 7786390';
deviceTitles['7786391'] = '11 - 7786391';



const DeviceSelect = ({title, onApply}) => {
  return (
    <Dropdown> 
    <Dropdown.Toggle className="col-12 mx-auto" variant="warning" id="dropdown-basic">
      {title}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => onApply('7785075')}>7785075</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786393')}>7786393</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786394')}>7786394</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786395')}>7786395</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786841')}>7786841</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786377')}>7786377</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786379')}>7786379</Dropdown.Item>

      <Dropdown.Item onClick={() => onApply('7786373')}>7786373</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786374')}>7786374</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786375')}>7786375</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786376')}>7786376</Dropdown.Item>

      <Dropdown.Item onClick={() => onApply('7786370')}>7786370</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786380')}>7786380</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786378')}>7786378</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786398')}>7786398</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786369')}>7786369</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786397')}>7786397</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786395')}>7786395</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786393')}>7786393</Dropdown.Item>

      <Dropdown.Item onClick={() => onApply('7786381')}>7786381</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786382')}>7786382</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786383')}>7786383</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786384')}>7786384</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786385')}>7786385</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786386')}>7786386</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786387')}>7786387</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786388')}>7786388</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786389')}>7786389</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786390')}>7786390</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786391')}>7786391</Dropdown.Item>

    </Dropdown.Menu>
    </Dropdown>

  )
}

const DeviceSelect_vayla = ({title, onApply}) => {
  return (
    <Dropdown>
    <Dropdown.Toggle className="col-12 mx-auto" variant="warning" id="dropdown-basic">
      {title}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => onApply('7786373')}>{deviceTitles['7786373']}</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786374')}>7786374 - Kaarina länsi</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786375')}>7786375 - Parainen itä</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786376')}>7786376 - Parainen länsi</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>

  )
}

const DeviceSelect_metsafibre = ({title, onApply}) => {
  return (
    <Dropdown>
    <Dropdown.Toggle className="col-12 mx-auto" variant="warning" id="dropdown-basic">
      {title}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => onApply('7786370')}>{deviceTitles['7786370']}</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786380')}>2 - 7786380</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786378')}>3 - 7786378</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786398')}>4 - 7786398</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786369')}>5 - 7786369</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786397')}>6 - 7786397</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786395')}>7 - 7786395</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786393')}>8 - 7786393</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>

  )
}


const DeviceSelect_rauma = ({title, onApply}) => {
  return (
    <Dropdown>
    <Dropdown.Toggle className="col-12 mx-auto" variant="warning" id="dropdown-basic">
      {title}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => onApply('7786381')}>{deviceTitles['7786381']}</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786382')}>2 - 7786382</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786383')}>3 - 7786383</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786384')}>4 - 7786384</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786385')}>5 - 7786385</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786386')}>6 - 7786386</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786387')}>7 - 7786387</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786388')}>8 - 7786388</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786389')}>9 - 7786389</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786390')}>10 - 7786390</Dropdown.Item>
      <Dropdown.Item onClick={() => onApply('7786391')}>11 - 7786391</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>

  )
}


//function Range(props) {
const RangeModalForm = ({show, onApply, onCancel, theme}) => {
  console.log('range theme', theme)
  //let calculatedStartDate = new Date();
  //calculatedStartDate.setHours(calculatedStartDate.getHours() - 24);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedRange, setSelectedRange] = useState('24h');
  const [selectedRefresh, setSelectedRefresh] = useState('Off');
  // TODO: Tätä listaa voisi käyttääkin
  let rangelist = ['15m', '60m', '2h', '3h', '6h', '24h', '7d', '30d', '60d'];
  let refreshlist = ['Off', '5s', '10s', '30s', '1m', '5m', '15m', '30m', '1h'];


  const applyRange = () => {
    /* Poistettu käytöstä koska lisätty valintaan tunnit ja minuutit
    console.log('applyRange ennen', selectedStartDate, selectedEndDate)
    selectedStartDate.setHours(0);
    selectedStartDate.setMinutes(0);
    selectedStartDate.setSeconds(0);

    selectedEndDate.setHours(23);
    selectedEndDate.setMinutes(59);
    selectedEndDate.setSeconds(59);
*/

    console.log('applyRange', selectedStartDate, selectedEndDate)

    const ret = {
      selectedStartDate: selectedStartDate,
      selectedEndDate: selectedEndDate,
      selectedRange: selectedRange,
      selectedRefresh: selectedRefresh
    }

    onApply(ret);
  } 

// Modalista pois: size="sm"
  return (
    <div>
    <Modal
      
      show={show}
      >
      <Modal.Header closeButton>
        <Modal.Title>
          Select range
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <Container fluid>
          <Row> 
            <Col>
              <DatePicker className="col-11" 
                selected={selectedStartDate} 
                onChange={(date) => 
                  { setSelectedStartDate(date); setSelectedRange(null); setSelectedRefresh('Off'); } } 
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect 
                timeFormat="HH:mm" 
              />
            </Col> 
            <Col>
              <DatePicker className="col-11"
              selected={selectedEndDate} 
              onChange={(date) => { setSelectedEndDate(date); setSelectedRange(null); setSelectedRefresh('Off'); } } 
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeSelect 
              timeFormat="HH:mm"
            />
            </Col>
          </Row>

          <Row>
            <Col>
              Range:{selectedRange} 
            </Col>
            <Col>
              <Dropdown>
                <Dropdown.Toggle className="col-10" variant="warning" id="dropdown-basic">
                  Range
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSelectedRange('15m')}>Last 15 min</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRange('60m')}>Last 60 min</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRange('2h')}>Last 2 h</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRange('3h')}>Last 3 h</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRange('6h')}>Last 6 h</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRange('24h')}>Last 24 h</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRange('7d')}>Last 7 days</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRange('30d')}>Last 30 days</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRange('60d')}>Last 60 days</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>

        </Row>
        <Row>
            <Col>
            Refresh:{selectedRefresh}
            </Col>
            <Col>

              <Dropdown>
                <Dropdown.Toggle className="col-10 mx-auto" variant="warning" id="dropdown-basic">
                  Refresh
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSelectedRefresh('Off')}>Off</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRefresh('5s')}>5s</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRefresh('10s')}>10s</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRefresh('30s')}>30s</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRefresh('1m')}>1m</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRefresh('5m')}>5m</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRefresh('15m')}>15m</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRefresh('30m')}>30m</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedRefresh('1h')}>1h</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              
            </Col>
          </Row>
          
      </Container>

    </Modal.Body>

    <Modal.Footer>
      <Button className="btn btn-primary" onClick={onCancel}  style={{backgroundColor: "#ce7e00", color: "black", borderColor: "#ce7e00" }}>Cancel</Button>
      <Button className="btn btn-primary" onClick={applyRange} style={{backgroundColor: "#ce7e00", color: "black", borderColor: "#ce7e00" }}>Apply</Button>
      {/*<Button className="btn btn-primary" onClick={applyRange} >Apply</Button>*/}
    </Modal.Footer>

    </Modal>
    </div>
  )
}

const SaveFileModalForm = ({show, onApply, onCancel, theme}) => {

  const [selectedFile, setSelectedFile] = useState("test.csv");
  const [selectedFolder, setSelectedFolder] = useState(null);

  // Comboboxi
  const [item, setItem] = useState({ saveFileType: "accelerometer" })
  const {saveFileType} = item;

  const handleSaveFileTypeChange = e => {
    e.persist();
    setItem(prevState => ({
      ...prevState,
      saveFileType: e.target.value
    }))
  }
  // ...Comboboxi

  const apply = () => {
    const ret = {
      filename: selectedFile,
      filetype: saveFileType
    }
    console.log('file apply', ret)
    onApply(ret);
  } 
/*
  const handleClose = () => {
    onclose();
    setSelectedFile('');
    setSelectedFolder(null);
  }

  const handleSave = () => {
    onSave(selectedFile, selectedFolder);
    handleClose();
  }

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFolder(acceptedFiles[0]);
    }
  }

  const { getRootProps, getInputProps } = useDropZone({
    onDrop,
    multiple: false,
  });
*/
  const onChange = (event) => {
    console.log('file', event.target.value)
    setSelectedFile(event.target.value);

  }

  const handleSubmit = (event) => {
    event.prevenDefault();
    console.log('file tänne tultiin')
  }


  console.log('save file form')
  return (
    <Modal
      size="sm"
      show={show}
      >
      <Modal.Header closeButton>
        <Modal.Title>
          Save
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>

      <Form >
      <Form.Group controlId="saveFileLocation" className="mb-3" >
        <Form.Label>Save database file as csv<br/>(Note! Default download folder).</Form.Label>
        <Form.Control id="filename" onChange={onChange} />
      </Form.Group>

      <Form.Group controlId="saveFileType">
        <Form.Label>Select</Form.Label>
        <Form.Check type="radio" value="accelerometer" label="Accelerometer" onChange={handleSaveFileTypeChange} checked={saveFileType === "accelerometer"}></Form.Check>
        <Form.Check type="radio" value="inclinometer" label="Inclinometer" onChange={handleSaveFileTypeChange} checked={saveFileType === "inclinometer"}></Form.Check>
        <Form.Check type="radio" value="inclinometer_max100" label="Inclinometer Max 100" onChange={handleSaveFileTypeChange} checked={saveFileType === "inclinometer_max100"}></Form.Check>        
      </Form.Group>

      </Form>
      </Modal.Body>

  <Modal.Footer>
    <Button className="btn btn-primary" onClick={onCancel}  style={{backgroundColor: "#ce7e00", color: "black", borderColor: "#ce7e00" }}>Cancel</Button>
    <Button className="btn btn-primary" onClick={apply} style={{backgroundColor: "#ce7e00", color: "black", borderColor: "#ce7e00" }}>Apply</Button>
    {/*<Button className="btn btn-primary" onClick={applyRange} >Apply</Button>*/}
  </Modal.Footer>

  </Modal>

  )
}


function App() {
  const [selectedRange, setSelectedRange] = useState('24h');
  const [selectedRefresh, setSelectedRefresh] = useState('Off');
  let startTime = new Date();
  startTime.setHours(startTime.getHours() - 24);
  const [selectedBeginTime, setSelectedBeginTime] = useState(startTime.toISOString());
  const [selectedEndTime, setSelectedEndTime] = useState(new Date().toISOString());
  const [selectedDevice, setSelectedDevice] = useState(0); // Testattava laite
  const [selectedDeviceTitle, setSelectedDeviceTitle] = useState('7786373 - HEIPPA'); // Testattava laite
  const [selectedDeviceLocation, setSelectedDeviceLocation] = useState(0); // TODO: location kuuluisi laitteen tietoihin suoraan

  const [selectedFftBeginTime, setSelectedFftBeginTime] = useState(null);
  const [selectedFftEndTime, setSelectedFftEndTime] = useState(null);

  // Käyttäjienhallinta
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState('');


  // Localstorageen tallennetut käyttäjätiedot
  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem('loggedUser');
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON);
      setUser(user);


      // Dropboxiin valmiiksi - TESTATAAN
      if (user.username === 'vayla' || user.username === 'helil' || user.username === 'janik' || user.username === 'arsal') {
        setSelectedDevice('7786373');
      }
      else if (user.username === 'metsafibre' || user.username === 'afry') {
        setSelectedDevice('7786370');
      }
      else if (user.username === 'sweco' || user.username === 'rauma') {
        setSelectedDevice('7786370');
      }
      else {
        setSelectedDevice('7786373');
      }

    }

  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log('loggin in with', username, password)

    try {
      const user = await loginService({
        username, password
      })

      window.localStorage.setItem(
        'loggedUser', JSON.stringify(user)
      );
      console.log('login - handlelogin user:', user)

      
      setUser(user);
      setUsername('');
      setPassword('');

      // Dropboxiin valmiiksi - TESTATAAN
      if (user.username === 'vayla' || user.username === 'helil' || user.username === 'janik' || user.username === 'arsal') {
        setSelectedDevice('7786373');
      }
      else if (user.username === 'metsafibre' || user.username === 'afry') {
        setSelectedDevice('7786370');
      }
      else if (user.username === 'sweco' || user.username === 'rauma') {
        setSelectedDevice('7786370');
      }
      else {
        setSelectedDevice('7786373');
      }


    } catch (exception) {
      console.log('error wrong credentials')
    }
  }

  const loginForm = () => (
    <>
    <div className="loginform-container">
    <form onSubmit={handleLogin}>

      <div className="loginlogo" >
      </div>

      

      <div>
        
        <input 
          type="text"
          value={username}

          placeholder='Username'
          name="Username"
          onChange={({ target }) => setUsername(target.value)}
        />
      </div>
      <div>
        
        <input 
          type="password"
          value={password}
          placeholder="Password"
          name="Password"
          onChange={({ target }) => setPassword(target.value)}
        />
      </div>
      <button className="btn btn-primary" type="submit" style={{marginTop: '10px', backgroundColor: "#ce7e00", color: "black", borderColor: "#ce7e00" }} >login</button>
    </form>
    </div>
    </>
  )



  // Aikarajausruutu
  const [rangeModalShow, setRangeModalShow] = useState(false);
  const showRangeModalForm = () => {
    console.log('showModal', rangeModalShow)
    setRangeModalShow(!rangeModalShow);
  }

  const [saveFileModalShow, setSaveFileModalShow] = useState(false);
  const showSaveFileModalForm = () => {
    setSaveFileModalShow(!saveFileModalShow);
  }

  const [mapModalShow, setMapModalShow] = useState(false);
  const showMapModalForm = () => {
    setMapModalShow(!mapModalShow);
  }

  // Dark / Light theme
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const toggleTheme = () => {
    let newTheme = (theme === 'light') ? 'dark' : 'light';
    setTheme(newTheme);
    
    //asetus(selectedRange, selectedRefresh, newTheme); // Hakee teeman väriset Grafana-paneelit
    populateCharts(selectedDevice, selectedBeginTime, selectedEndTime, selectedRange, selectedRefresh, newTheme);
  }

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
  }, [theme]);
  // .. Dark / Light theme



  useEffect(() => {
    console.log('app starting..', selectedDevice,selectedBeginTime, selectedEndTime)
    populateCharts(selectedDevice, selectedBeginTime, selectedEndTime, selectedRange, selectedRefresh, theme);;
   
    // TODO: OTA TÄMÄ KÄYTTÖÖN HETI KUN MUUTEN TOIMII!!
    //fftResults(selectedDevice, selectedBeginTime, selectedEndTime);

    //mainForm();
     
    // Alkuasetuksia...
    updateDeviceLocation(selectedDevice);

  }, []);


  const [panel10, setPanel10] = useState(null);
  const [panel11, setPanel11] = useState(null);
  const [panel1, setPanel1] = useState(null);
  const [panel2, setPanel2] = useState(null);
  const [panel3, setPanel3] = useState(null);
  const [panel4, setPanel4] = useState(null);
  const [panel5, setPanel5] = useState(null);
  const [panel6, setPanel6] = useState(null);
  const [panel7, setPanel7] = useState(null);
  const [panel8, setPanel8] = useState(null);
  const [panel9, setPanel9] = useState(null);


  //const asetus = (aika, refresh, newTheme=theme) => {
    //  const asetus2 = (startDate, endDate, newTheme=theme) => {
      //console.log('valittu laite', device, 'start:',beginTime, 'end:',endTime, 'range:',selectedRange, 'refresh:',selectedRefresh, 'theme:', theme);
  // valittu laite 7786394 start: 8.1.2024 end: 26.1.2024 range: null refresh: Off theme: dark
  const populateCharts = (device, start, end, range, refresh, newtheme=theme) => {

    console.log('populatecharts test range', range, start, end)
    // device, beginTime, endTime, selectedRange, selectedRefresh, theme
    let new_refresh = (refresh != 'Off') ? '&refresh=' + refresh : '';
    // 2
    //let refresh = ''; //'&refresh=' + '';


    // TODO: let from 
    var  from = '';
    if (range == null) {
      let start_ms = new Date(start).getTime();
      let end_ms = new Date(end).getTime();
      //let start_ms = 1706189294000;
      //let end_ms = 1706189310000;

      from = `${start_ms}&to=${end_ms}`;     
      console.log('from', from) 
    }
    else
      from = `now-${range}&to=now`;


    // Kiihtari
    //let aseta = `http://51.15.114.61:3000/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=8&theme=${newtheme}&var-sensorid=${device}`;
    //let aseta = `https://opus.iottech.fi/grafana/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=8&theme=${newtheme}&var-sensorid=${device}`;
    let aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=8&theme=${newtheme}&var-sensorid=${device}`;
    
    console.log('aseta', aseta)
    setPanel1(aseta);

    // Tilt
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=9&theme=${newtheme}&var-sensorid=${device}`;
    setPanel2(aseta);

    // fft painavin taajuus
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=12&theme=${newtheme}&var-sensorid=${device}`;
    setPanel3(aseta);

    // fft taajuudet
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=13&theme=${newtheme}&var-sensorid=${device}`;
    setPanel4(aseta);

    // fft painot
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=14&theme=${newtheme}&var-sensorid=${device}`;
    setPanel5(aseta);

    // fft:n aikainen kiihtari
    //let teststart = 1706189294000;
    //let testend = 1706189310000;
    let teststart = selectedFftBeginTime;
    let testend = selectedFftEndTime;
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1&from=${teststart}&to=${testend}&panelId=8&theme=${newtheme}&var-sensorid=${device}`;
    console.log('setpanel6', aseta)
    setPanel6(aseta);

    // Inclinometer - Max 50
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=16&theme=${newtheme}&var-sensorid=${device}`;
    setPanel7(aseta);

    // Inclinometer - Min 50
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=17&theme=${newtheme}&var-sensorid=${device}`;
    setPanel8(aseta);

    // Muut kuten location, temp, hum etc.
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=15&theme=${newtheme}&var-sensorid=${device}`;
    setPanel9(aseta);


    // Tilt detect
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=18&theme=${newtheme}&var-sensorid=${device}`;
    setPanel10(aseta);

    // Tilt detect table
    aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1${new_refresh}&from=${from}&panelId=19&theme=${newtheme}&var-sensorid=${device}`;
    setPanel11(aseta);

  }

  // FFT on poikkeus chart, jota päivitetään aina kun valittu fft muuttuu
  const updateFftChart = (device, beginTime, endTime) => {
    let refresh = selectedRefresh;
    let newtheme = theme;
    //let device = selectedDevice;
    let aseta = `${grafana_url}/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1&from=${beginTime}&to=${endTime}&panelId=8&theme=${newtheme}&var-sensorid=${device}`;
    console.log('setpanel6-test', aseta)
    setPanel6(aseta);
  }

  const databaseQuery = async (filename, filetype) => {
    let type = filetype.toLowerCase();
    //let type = 'accelerometer';

    let url = `${iottech_url}/database?sensor=${selectedDevice}&begin=${selectedBeginTime}&end=${selectedEndTime}&type=${type}`
    await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/csv'
      },      
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${filename}_${type}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })

  }

  const saveFromDatabase = (filename,filetype) => {
    console.log('saveFromDatabase', filename, filetype, selectedDevice, selectedBeginTime, selectedEndTime)
    
    //http://51.15.114.61:8086/api/v2/query?org=iottech 
    //-H 'Authorization: Token _gzbhFQFAwaLeqzib1e0ylgrHjTmxfgX-UbURBfnxT80kgM07dkk2ynhfMksf1kyt98KXC9TJNw4D6kpFVEEjA==' 
    //-H 'Accept:application/csv' 
    //-H 'Content-type:application/vnd.flux' 
    //-d 'from(bucket:"level") |> range(start: -3d) |> aggregateWindow(every: 24h, fn: mean) |> drop(columns: ["_start", "_stop", "_result"])'

    //curl "http://51.15.114.61:8086/api/v2/query?org=iottech" -H "Authorization: Token _gzbhFQFAwaLeqzib1e0ylgrHjTmxfgX-UbURBfnxT80kgM07dkk2ynhfMksf1kyt98KXC9TJNw4D6kpFVEEjA==" -H "Accept:application/csv" -H "Content-type:application/vnd.flux" -d 'from(bucket:"level") |> range(start: 2023-11-07T20:50:00Z, stop: 2023-11-07T22:15:00Z) |> filter(fn: (r) => r._measurement == "accelerometer" and r.sensor_id == "7786394") |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value") ' --output data_7786394.csv


    console.log('fetch')
    databaseQuery(filename, filetype);
    //databaseQuery(filename, 'inclinometer');
    //databaseQuery(filename, 'inclinometer_max100');
    //fetch("http://172.16.0.103:7095/database", {
      
      //fetch("http://51.15.114.61:7095/database", {
    /*        
      fetch("http://51.15.114.61:7095/database", {
      method: "GET",
      headers: {
        'Content-Type': 'application/zip'
      },      
      */
    // TODO: Tyyppi accelerometer / inclinometer / 
    /*
    let type = 'accelerometer';

     let url = `${iottech_url}/database?sensor=${selectedDevice}&begin=${selectedBeginTime}&end=${selectedEndTime}&type=${type}`
     fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/csv'
      },      
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${filename}_acc.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })

    .then(() => {

      // ---------------------

      // Tässä testataan eri tyyppisiä hakuja ja tiedostoon tallennukseen 
      type = 'inclinometer';

      url = `${iottech_url}/database?sensor=${selectedDevice}&begin=${selectedBeginTime}&end=${selectedEndTime}&type=${type}`
        fetch(url, {
        method: "GET",
        headers: {
          'Content-Type': 'application/csv'
        },      
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${filename}_inc.csv`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });

    });
*/



    // ---------------------

  
    /*
    let fileURL = 'test.pdf';
    fetch('https://cors-anywhere.herokuapp.com/' + fileURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'Access-Control-Allow-Origin: *'
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName.pdf`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
    */
  }

  const onSelectDevice = (device) => {
    console.log('valittu laite', device, 'start:',selectedBeginTime, 'end:', selectedEndTime, 'range:',selectedRange, 'refresh:',selectedRefresh, 'theme:', theme);
    setSelectedDevice(device);
    setSelectedDeviceTitle(device + ' TESTING')
    populateCharts(device, selectedBeginTime, selectedEndTime, selectedRange, selectedRefresh, theme);
    fftResults(device, selectedBeginTime, selectedEndTime);//!!
    updateDeviceLocation(device);
  }

  const applyRange = (event) => {
    console.log('applyRange', event)
    setRangeModalShow(false);

    // Käytetään viimeisimpiä mittauksia jos se on annettuna
    if (event.selectedRange) {
      //asetus(event.selectedRange, event.selectedRefresh);
      setSelectedRange(event.selectedRange);
      setSelectedRefresh(event.selectedRefresh);
      console.log('applyrange-test:', event);
      //populateCharts(event.selectedRange);
      
      // Lasketaan rangen perusteella alku ja loppuajat
      let end = new Date().toISOString();
      let begin = moment(end).subtract(1, 'days').toISOString();
      console.log('selectedrange', event.selectedRange, selectedBeginTime, selectedEndTime, '->', begin, end);


      
      
      populateCharts(selectedDevice, selectedBeginTime, selectedEndTime, event.selectedRange, event.selectedRefresh, theme);
      fftResults(selectedDevice, begin, end);
    }
    else {
      // Nollataan myös last mittauksia
      setSelectedRange(null);
      //asetus2(event.startDate, event.endDate);
      //populateCharts(null);
      let begin = new Date(event.selectedStartDate).toISOString();
      let end = new Date(event.selectedEndDate).toISOString();
      console.log('range', event.selectedStartDate, event.selectedEndDate)
      setSelectedBeginTime(begin);
      setSelectedEndTime(end);



      populateCharts(selectedDevice, event.selectedStartDate, event.selectedEndDate, null, selectedRefresh, theme);
      fftResults(selectedDevice, event.selectedStartDate, event.selectedEndDate);
    }
  }

  const onCancelRange = () => {
    setRangeModalShow(false);
  }


  const applySaveFile = (event) => {
    setSaveFileModalShow(false);
    console.log('file event', event)
    //alert('heippa' + event.filename)
    let filename = event.filename;
    let filetype = event.filetype;
    saveFromDatabase(filename, filetype);
    
  }

  const onCancelSaveFile = () => {
    setSaveFileModalShow(false);
  }

  const onMapClose = () => {
    setMapModalShow(false);
  }

  const iframeTest = () => {
    console.log('iframe clicked')
  }


  // Avaa karttanäkymän
  // Vanha JY apikey: AIzaSyBdYYh8A_eanLza5ULueKeemQxwAeJ1iZk
  const MapModalForm = ({show, onClose, theme}) => {
    const position = selectedDeviceLocation ? selectedDeviceLocation : { lat: 61.68713, lng: 27.27047 }; //{ lat: 61.68713, lng: 27.27047 };
    console.log('mapmodalform position', position)
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState("");

    return (
      <Modal
      size="xl"
      show={show}
      >

      <Modal.Body>
      <APIProvider apiKey={"AIzaSyAPlS78wBR4M--706zhvPTuw9NZ6f7ttv0"}>
        <div style={{height: "85vh"}}>

          <Map zoom={12} center={position} mapId="d4431eac82eb3ca9">
            <AdvancedMarker position={position} onClick={() => { setOpen(true); setInfo("IotTech HQ, Mikkeli, Finland") }}>
              <Pin
                background={"#ce7e00"}
                borderColor={"white"}
                glyphColor={"#000000"}
              />
            </AdvancedMarker>
            {open && (
              <InfoWindow position={position} onCloseClick={() => {setOpen(false); setInfo("");}}>
                <p style={{color: 'black'}}>{selectedDevice}</p>
              </InfoWindow>
            )}
          </Map>


        </div>
      </APIProvider>
      </Modal.Body>
      <Modal.Footer>
        <p>{info}</p>
        <Button className="btn btn-primary" onClick={onClose} style={{backgroundColor: "#ce7e00", color: "black", borderColor: "#ce7e00" }} >Close</Button>
      </Modal.Footer>
      </Modal>
    )
  }





  // Esim. yksi fft tulos 2024-01-25T13:28:30Z välillä 1706189294000 - 1706189310000, sensorid=7786394
  /* 0
  :
  {freq: '5.8', weight: '0.744'}
  1
  : 
  {freq: '5.43', weight: '0.456'}
  2
  : 
  {freq: '10.8', weight: '0.388'}
  3
  : 
  {freq: '10.99', weight: '0.0953'}
  4
  : 
  {freq: '11.6', weight: '0.0863'}
  5
  : 
  {freq: '4.82', weight: '0.0801'}
  6
  : 
  {freq: '6.04', weight: '0.0675'}
  7
  : 
  {freq: '11.11', weight: '0.0557'} */
  // Hakee fft tulokset backendistä
  // Args: begin, end
  // Kts. https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  const fftResults = (device, begin, end) => {
    //http://51.15.114.61:8086/api/v2/query?org=iottech 
    //-H 'Authorization: Token _gzbhFQFAwaLeqzib1e0ylgrHjTmxfgX-UbURBfnxT80kgM07dkk2ynhfMksf1kyt98KXC9TJNw4D6kpFVEEjA==' 
    //-H 'Accept:application/csv' 
    //-H 'Content-type:application/vnd.flux' 
    //-d 'from(bucket:"level") |> range(start: -3d) |> aggregateWindow(every: 24h, fn: mean) |> drop(columns: ["_start", "_stop", "_result"])'
    console.log('fetch fft')
    //fetch("http://172.16.0.103:7095/database", {
      
      //fetch("http://51.15.114.61:7095/fft", {
    //fetch("http://127.0.0.1:7095/fft", {
    //  method: "GET",
    //  headers: {
    //    'Content-Type': 'application/json'
    //  },      
    //})
    
    let beginQuery = Math.floor(new Date(begin).getTime() / 1000);
    let endQuery = Math.floor(new Date(end).getTime() / 1000);


    //http://51.15.114.61:7095/fft?sensor=7786394&begin=1695540600000&end=1695627000000





    //let query = `http://51.15.114.61:7095/fft?sensor=${device}&begin=${beginQuery}&end=${endQuery}`;
    let query = `${iottech_url}/fft?sensor=${device}&begin=${beginQuery}&end=${endQuery}`;
    console.log('query', query)

    //fetch("http://172.16.0.103:7095/fft?sensor=7786394&begin=1704481524&end=1706189310")
    //fetch("http://51.15.114.61:7095/fft?sensor=7786394&begin=1704481524&end=1706469306")
    fetch(query)
    //fetch("http://127.0.0.1:7095/fft")
    .then((response) => {
      // Create blob link to download
      //console.log('response', response)
      return response.json();
    })
    .then((data) => { 
      console.log('fft tulokset:',data)

      let beginResult = data['begin'];
      let endResult = data['end'];


      console.log('aikaikkuna', beginResult, endResult, data);

      updateFftChart(device, beginResult, endResult);
      setSelectedFftBeginTime(beginResult);
      setSelectedFftEndTime(endResult);
    });

  }  

  // Päivittää valitun laitteen sijaintitiedot
  const updateDeviceLocation = (device) => {
    let query = `${iottech_url}/location?sensor=${device}`;
    console.log('query', query)

    fetch(query)
    //fetch("http://127.0.0.1:7095/fft")
    .then((response) => {
      // Create blob link to download
      console.log('response', response)
      return response.json();
    })
    .then((data) => { 
      let location = new Object();
      location['lat'] = Number(data['lat']);
      location['lng'] = Number(data['long']);

      if ( isNaN(location['lat']) || isNaN(location['lng']) ) {
        setSelectedDeviceLocation(0);
      }
      else {
        setSelectedDeviceLocation(location);
      }

      console.log('location', location)
    });
  }

/*  
  <div className="logo" style={{padding: '5px' }} >
  <img src={logo} style={{width:'100%' }} align="left" alt="IOTTECH" />
</div>
*/

  const mainForm = () => {
    // Asiakaskohtaiset räätälöinnit
    // TODO: Näihin käyttäjätasot
    if (user.username === 'vayla' || user.username === 'helil' || user.username === 'janik' || user.username === 'arsal') {
      // Dropboxin oletusvalinta      
      //setSelectedDevice('7786373');
      return mainForm_vayla();
    }
    else if (user.username === 'metsafibre' || user.username === 'afry') {
      //setSelectedDevice('7786370');
      return mainForm_metsafibre();
    }
    else if (user.username === 'sweco' || user.username === 'rauma') {
      //setSelectedDevice('7786370');
      return mainForm_rauma();
    }

    // Muuten oletuksena näytetään kaikki
    return (
      <>
        <div className="container">

          <div className="container">

            <div className="logo" style={{ padding: '5px' }} >
              <img style={{ width: '100%' }} align="left" alt="IOTTECH" />
            </div>
          </div>



          <div className="container">
            <div style={{ marginRight: "10px" }}>Time range:</div>
            {(selectedRange !== null)
              ? <div style={{ marginRight: "10px" }}>Last {selectedRange}</div>
              : <div style={{ marginRight: "10px" }}>{moment(selectedBeginTime).format('DD.MM.YYYY HH:mm')} - {moment(selectedEndTime).format('DD.MM.YYYY HH.mm')}</div>}
          </div>

          <div className="container">
            <div style={{ marginRight: "10px" }}>Refresh:</div>
            <div style={{ marginRight: "10px" }}>{selectedRefresh}</div>
          </div>






          {/* Siirrä tämä omaan funktioon */}
          <div className="container">
            <DeviceSelect title={selectedDevice} onApply={onSelectDevice}></DeviceSelect>
          </div>


          <div className="container">

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={clock} style={{ height: '30px' }} align="left" alt="Select range" onClick={showRangeModalForm} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={floppy} style={{ height: '30px' }} align="left" alt="Save data in csv" onClick={showSaveFileModalForm} />
              {/*<img src={floppy} style={{height:'30px'}} align="left" alt="Save data in csv"  onClick={saveFileForm} /> */}

            </div>
            <div className="floppy" style={{ padding: '5px' }} >
              <img src={brilliance} style={{ height: '30px' }} align="left" alt="Theme" onClick={toggleTheme} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={map} style={{ height: '30px' }} align="left" alt="Map" onClick={showMapModalForm} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={gear} style={{ height: '30px' }} align="left" alt="Config" />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={logout} style={{ height: '30px' }} align="left" alt="Logout" Tooltip="test" onClick={() => {window.localStorage.removeItem('loggedUser'); setUser('')}} />
            </div>

            {/*
        <ReactSwitch
          checked={theme}
          onChange={handleChange}
        />
      */}


            <RangeModalForm width="20%" show={rangeModalShow} onApply={applyRange} onCancel={onCancelRange} theme={theme} />
            <SaveFileModalForm width="20%" show={saveFileModalShow} onApply={applySaveFile} onCancel={onCancelSaveFile} theme={theme} />

            <MapModalForm width="80%" show={mapModalShow} onClose={onMapClose} theme={theme} />

          </div>

        </div>


        <hr style={{ width: '100%', height: 0, color: '#000000', borderColor: '#000000', backgroundcolor: '#ce7e00', marginTop: '5px' }} />

        {/*<iframe src=`http://51.15.114.61:3000/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1&refresh=1m&from=now-60m&to=now&panelId=1` width="49%" height="200" frameborder="0"></iframe>*/}

        <iframe src={panel10} width="49%" height="200" frameBorder="0"></iframe> 
        <iframe src={panel11} width="49%" height="200" frameBorder="0"></iframe>
        <iframe src={panel1} width="49%" height="200" frameBorder="0" onClick={iframeTest}></iframe>
        <iframe src={panel2} width="49%" height="200" frameBorder="0"></iframe>
        <iframe src={panel3} width="49%" height="200" frameBorder="0"></iframe>
        <iframe src={panel4} width="49%" height="200" frameBorder="0"></iframe>
        <iframe src={panel5} width="49%" height="200" frameBorder="0"></iframe>
        <iframe src={panel6} width="49%" height="200" frameBorder="0"></iframe>
        <iframe src={panel7} width="49%" height="200" frameBorder="0"></iframe>
        <iframe src={panel8} width="49%" height="200" frameBorder="0"></iframe>
        <iframe src={panel9} width="98%" height="200" frameBorder="0"></iframe>



        {/* Recharts komponentti
        <Chart/>
  */}
{/*
        <div className="logout" style={{ marginRight: '10px' }} >
          <button class="btn btn-primary" type="button" onClick={() => { window.localStorage.removeItem('loggedUser'); setUser('') }}>logout</button>
        </div>
*/}
      </>
    );

  }


  const mainForm_vayla = () => {
    console.log('vayla mainform user', user)

    return (
      <>
        <div className="container">

          <div className="container">

            <div className="logo" style={{ padding: '5px' }} >
              <img style={{ width: '100%' }} align="left" alt="IOTTECH" />
            </div>
          </div>



          <div className="container">
            <div style={{ marginRight: "10px" }}>Time range:</div>
            {(selectedRange !== null)
              ? <div style={{ marginRight: "10px" }}>Last {selectedRange}</div>
              : <div style={{ marginRight: "10px" }}>{moment(selectedBeginTime).format('DD.MM.YYYY HH:mm')} - {moment(selectedEndTime).format('DD.MM.YYYY HH.mm')}</div>}
          </div>

          <div className="container">
            <div style={{ marginRight: "10px" }}>Refresh:</div>
            <div style={{ marginRight: "10px" }}>{selectedRefresh}</div>
          </div>






          {/* Siirrä tämä omaan funktioon */}
          <div className="container">
            <DeviceSelect_vayla title={deviceTitles[selectedDevice]} onApply={onSelectDevice}></DeviceSelect_vayla>
          </div>


          <div className="container">

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={clock} style={{ height: '30px' }} align="left" alt="Select range" onClick={showRangeModalForm} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={floppy} style={{ height: '30px' }} align="left" alt="Save data in csv" onClick={showSaveFileModalForm} />
              {/*<img src={floppy} style={{height:'30px'}} align="left" alt="Save data in csv"  onClick={saveFileForm} /> */}

            </div>
            <div className="floppy" style={{ padding: '5px' }} >
              <img src={brilliance} style={{ height: '30px' }} align="left" alt="Theme" onClick={toggleTheme} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={map} style={{ height: '30px' }} align="left" alt="Map" onClick={showMapModalForm} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={gear} style={{ height: '30px' }} align="left" alt="Config" />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={logout} style={{ height: '30px' }} align="left" alt="Logout" Tooltip="test" onClick={() => {window.localStorage.removeItem('loggedUser'); setUser('')}} />
            </div>

            {/*
        <ReactSwitch
          checked={theme}
          onChange={handleChange}
        />
      */}


            <RangeModalForm width="20%" show={rangeModalShow} onApply={applyRange} onCancel={onCancelRange} theme={theme} />
            <SaveFileModalForm width="20%" show={saveFileModalShow} onApply={applySaveFile} onCancel={onCancelSaveFile} theme={theme} />

            <MapModalForm width="80%" show={mapModalShow} onClose={onMapClose} theme={theme} />

          </div>

        </div>


        <hr style={{ width: '100%', height: 0, color: '#000000', borderColor: '#000000', backgroundcolor: '#ce7e00', marginTop: '5px' }} />

        {/*<iframe src=`http://51.15.114.61:3000/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1&refresh=1m&from=now-60m&to=now&panelId=1` width="49%" height="200" frameborder="0"></iframe>*/}

        <iframe src={panel10} width="49%" height="400" frameBorder="0"></iframe> 
        <iframe src={panel11} width="49%" height="400" frameBorder="0"></iframe>
        <iframe src={panel1} width="49%" height="400" frameBorder="0" onClick={iframeTest}></iframe>
        <iframe src={panel2} width="49%" height="400" frameBorder="0"></iframe>
      </>
    );

  }


  // Metsä fibre ja Afry
  const mainForm_metsafibre = () => {
    console.log('metsäfibre mainform user', user)

    return (
      <>
        <div className="container">

          <div className="container">

            <div className="logo" style={{ padding: '5px' }} >
              <img style={{ width: '100%' }} align="left" alt="IOTTECH" />
            </div>
          </div>



          <div className="container">
            <div style={{ marginRight: "10px" }}>Time range:</div>
            {(selectedRange !== null)
              ? <div style={{ marginRight: "10px" }}>Last {selectedRange}</div>
              : <div style={{ marginRight: "10px" }}>{moment(selectedBeginTime).format('DD.MM.YYYY HH:mm')} - {moment(selectedEndTime).format('DD.MM.YYYY HH.mm')}</div>}
          </div>

          <div className="container">
            <div style={{ marginRight: "10px" }}>Refresh:</div>
            <div style={{ marginRight: "10px" }}>{selectedRefresh}</div>
          </div>






          {/* Siirrä tämä omaan funktioon */}
          <div className="container">
            <DeviceSelect_metsafibre title={deviceTitles[selectedDevice]} onApply={onSelectDevice}></DeviceSelect_metsafibre>
          </div>


          <div className="container">

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={clock} style={{ height: '30px' }} align="left" alt="Select range" onClick={showRangeModalForm} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={floppy} style={{ height: '30px' }} align="left" alt="Save data in csv" onClick={showSaveFileModalForm} />
              {/*<img src={floppy} style={{height:'30px'}} align="left" alt="Save data in csv"  onClick={saveFileForm} /> */}

            </div>
            <div className="floppy" style={{ padding: '5px' }} >
              <img src={brilliance} style={{ height: '30px' }} align="left" alt="Theme" onClick={toggleTheme} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={map} style={{ height: '30px' }} align="left" alt="Map" onClick={showMapModalForm} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={gear} style={{ height: '30px' }} align="left" alt="Config" />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={logout} style={{ height: '30px' }} align="left" alt="Logout" Tooltip="test" onClick={() => {window.localStorage.removeItem('loggedUser'); setUser('')}} />
            </div>

            {/*
        <ReactSwitch
          checked={theme}
          onChange={handleChange}
        />
      */}


            <RangeModalForm width="20%" show={rangeModalShow} onApply={applyRange} onCancel={onCancelRange} theme={theme} />
            <SaveFileModalForm width="20%" show={saveFileModalShow} onApply={applySaveFile} onCancel={onCancelSaveFile} theme={theme} />

            <MapModalForm width="80%" show={mapModalShow} onClose={onMapClose} theme={theme} />

          </div>

        </div>


        <hr style={{ width: '100%', height: 0, color: '#000000', borderColor: '#000000', backgroundcolor: '#ce7e00', marginTop: '5px' }} />

        {/*<iframe src=`http://51.15.114.61:3000/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1&refresh=1m&from=now-60m&to=now&panelId=1` width="49%" height="200" frameborder="0"></iframe>*/}

        <iframe src={panel10} width="49%" height="400" frameBorder="0"></iframe> 
        <iframe src={panel11} width="49%" height="400" frameBorder="0"></iframe>
        <iframe src={panel1} width="49%" height="400" frameBorder="0" onClick={iframeTest}></iframe>
        <iframe src={panel2} width="49%" height="400" frameBorder="0"></iframe>
      </>
    );

  }  

  // ****** rauma
  const mainForm_rauma = () => {
    console.log('rauma mainform user', user)

    return (
      <>
        <div className="container">

          <div className="container">

            <div className="logo" style={{ padding: '5px' }} >
              <img style={{ width: '100%' }} align="left" alt="IOTTECH" />
            </div>
          </div>



          <div className="container">
            <div style={{ marginRight: "10px" }}>Time range:</div>
            {(selectedRange !== null)
              ? <div style={{ marginRight: "10px" }}>Last {selectedRange}</div>
              : <div style={{ marginRight: "10px" }}>{moment(selectedBeginTime).format('DD.MM.YYYY HH:mm')} - {moment(selectedEndTime).format('DD.MM.YYYY HH.mm')}</div>}
          </div>

          <div className="container">
            <div style={{ marginRight: "10px" }}>Refresh:</div>
            <div style={{ marginRight: "10px" }}>{selectedRefresh}</div>
          </div>






          {/* Siirrä tämä omaan funktioon */}
          <div className="container">
            <DeviceSelect_rauma title={deviceTitles[selectedDevice]} onApply={onSelectDevice}></DeviceSelect_rauma>
          </div>


          <div className="container">

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={clock} style={{ height: '30px' }} align="left" alt="Select range" onClick={showRangeModalForm} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={floppy} style={{ height: '30px' }} align="left" alt="Save data in csv" onClick={showSaveFileModalForm} />
              {/*<img src={floppy} style={{height:'30px'}} align="left" alt="Save data in csv"  onClick={saveFileForm} /> */}

            </div>
            <div className="floppy" style={{ padding: '5px' }} >
              <img src={brilliance} style={{ height: '30px' }} align="left" alt="Theme" onClick={toggleTheme} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={map} style={{ height: '30px' }} align="left" alt="Map" onClick={showMapModalForm} />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={gear} style={{ height: '30px' }} align="left" alt="Config" />
            </div>

            <div className="floppy" style={{ padding: '5px' }} >
              <img src={logout} style={{ height: '30px' }} align="left" alt="Logout" Tooltip="test" onClick={() => {window.localStorage.removeItem('loggedUser'); setUser('')}} />
            </div>

            {/*
        <ReactSwitch
          checked={theme}
          onChange={handleChange}
        />
      */}


            <RangeModalForm width="20%" show={rangeModalShow} onApply={applyRange} onCancel={onCancelRange} theme={theme} />
            <SaveFileModalForm width="20%" show={saveFileModalShow} onApply={applySaveFile} onCancel={onCancelSaveFile} theme={theme} />

            <MapModalForm width="80%" show={mapModalShow} onClose={onMapClose} theme={theme} />

          </div>

        </div>


        <hr style={{ width: '100%', height: 0, color: '#000000', borderColor: '#000000', backgroundcolor: '#ce7e00', marginTop: '5px' }} />

        {/*<iframe src=`http://51.15.114.61:3000/d-solo/e8836cce-36b7-407c-ab6c-2f84817a8389/level?orgId=1&refresh=1m&from=now-60m&to=now&panelId=1` width="49%" height="200" frameborder="0"></iframe>*/}

        <iframe src={panel10} width="49%" height="400" frameBorder="0"></iframe> 
        <iframe src={panel11} width="49%" height="400" frameBorder="0"></iframe>
        <iframe src={panel1} width="49%" height="400" frameBorder="0" onClick={iframeTest}></iframe>
        <iframe src={panel2} width="49%" height="400" frameBorder="0"></iframe>
      </>
    );

  }  
  // *****rauma



  return (
    <div className={`App ${theme}`}>

      {/*
  <header className="App-header">
    <img src={logo} className="App-logo" alt="logo" /> 
  </header>
  */}



        {!user && loginForm()}
        {user && mainForm()}

    </div>

  )
}




//fftResults();

export default App;
